import Controller from './controller'

export default class extends Controller {
  connect() {
    this.scroll()
    window.addEventListener('scroll', this.scroll)
  }

  disconnect() {
    window.removeEventListener('scroll', this.scroll)
  }

  scroll = () => {
    this.element.style.width = this.getScrollPercent + '%'
  }
  
  get getScrollPercent() {
    var h = document.documentElement, 
        b = document.body,
        st = 'scrollTop',
        sh = 'scrollHeight';
        
    return (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;
  }
}
