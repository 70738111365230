import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  constructor(...params) {
    super(...params)
    this.setOptions()
  }

  setOptions() {
    if (!this.constructor.options) {
      return
    }

    let overrides = this.data.get('options')

    if (overrides) {
      try {
        overrides = JSON.parse(overrides)
      } catch (error) {
        console.error('Error parsing component options', this.element, overrides)
        overrides = {}
      }
    }

    this.options = Object.assign({}, this.constructor.options || {}, overrides || {})
  }

  emit(name, options, element = this.element) {
    const event = new CustomEvent(name, {
      bubbles: false,
      cancelable: false,
      detail: null,
      ...options,
    })

    element.dispatchEvent(event)
  }
}
