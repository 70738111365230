import Controller from './controller'

export default class extends Controller {
  static targets = ['image', 'link', 'mover']

  initialize() {
    const firstImage = this.imageTargets.find((image) => image.dataset.imageLinksIndex != null)

    if (firstImage) {
      firstImage.classList.add('is-shown')

      this.firstIndex = Number(firstImage.dataset.imageLinksIndex)
      this.index = this.firstIndex
      
      if (this.hasMoverTarget) {
        this.move(this.index)
      }
    }
  }
  
  move(index) {
    this.moverTarget.setAttribute('style', 'transform: translateX(' + -(index * 100) + '%);')
  }

  hoverLink(ev) {
    const index = this.linkTargets.indexOf(ev.target)

    if (index !== this.index) {
      const image = this.getImage(index)

      if (image) {
        image.classList.add('is-shown')

        if (this.index != null) {
          this.getImage(this.index).classList.remove('is-shown')
        }

        this.index = index
      }
      
      if (this.hasMoverTarget) {
        this.move(this.index)
      }
    }
  }

  getImage(index) {
    return this.imageTargets.find((image) => image.dataset.imageLinksIndex === index.toString())
  }
}
