import Controller from './controller'
import { isSoundEnabled, toggleSound } from '../sound'

export default class extends Controller {
  static outlets = ['tooltip']

  connect() {
    document.addEventListener('sound:enable', () => {
      this.tooltipOutlet?.hide('Click here to mute')
    })
    document.addEventListener('sound:disable', () => {
      this.tooltipOutlet?.hide('Click here to unmute')
    })
  }

  tooltipOutletConnected() {
    if (isSoundEnabled()) {
      this.tooltipOutlet.setText('Click here to mute')
    }
  }

  toggle() {
    toggleSound()
  }
}
