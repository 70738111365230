import Controller from './controller'
import Modal from './modal_controller'

export default class extends Controller {
  static outlets = ['filter', 'modal']

  popState({ state }) {
    // Don't handle history entries with no state associated
    if (!state) {
      return
    }

    // If a modal with a URL (set via data-modal-url-value) is currently open, and its ID doesn't
    // match this history entry - then close the modal
    if (this.hasModalOutlet && this.modalOutlet.id !== state.modal?.id) {
      this.modalOutlet.close()
      return
    }

    // If this history entry has modal data - then attempt to open the matching modal
    if (state.modal?.id) {
      if (Modal.open(state.modal.id)) {
        return
      } else {
        // If the modal was not found, the standalone page for the modal will be visited. This is a hack to zero the
        // scroll position on that modal page, rather than using the scroll position of the host page where the modal was opened
        const cachedScrollY = Turbo.navigator.history.restorationData[state.cachedTurbo.restorationIdentifier]?.scrollPosition.y
        if (cachedScrollY) {
          Turbo.navigator.history.restorationData[state.cachedTurbo.restorationIdentifier].scrollPosition.y = 0
          window.addEventListener('turbo:load', () => {
            Turbo.navigator.history.restorationData[state.cachedTurbo.restorationIdentifier].scrollPosition.y = cachedScrollY
          }, { once: true })
        }
      }
    }

    // If this history entry has filter data - then fetch the matching filter results
    if (this.hasFilterOutlet && state.filter?.id === this.filterOutlet.idValue) {
      this.filterOutlet.fetch({
        ...state.filter,
        updateUrl: false,
      })
      return
    }

    // If this history entry is from a previous session, and hasn't matched a modal/filter above -
    // use full navigation to restore the page. Without this, it's possible to get stuck when doing
    // something like the following:
    //   1. Visit /tile-finder
    //   2. Open a tile modal
    //   3. Click a link in the tile modal to another page
    //   4. Use browser back button to visit tile modal page
    //   5. Use browser back button to attempt to visit /tile-finder again (won't work)
    if (!state.turbo) {
      if (state.cachedTurbo) {
        Turbo.navigator.history.location = new URL(location.href);
        const { restorationIdentifier, restorationIndex } = state.cachedTurbo;
        Turbo.navigator.history.restorationIdentifier = restorationIdentifier
        const direction = restorationIndex > Turbo.navigator.history.currentIndex ? "forward" : "back";
        Turbo.navigator.history.delegate.historyPoppedToLocationWithRestorationIdentifierAndDirection(Turbo.navigator.history.location, restorationIdentifier, direction);
        Turbo.navigator.history.currentIndex = restorationIndex
      } else if (state.url) {
        Turbo.visit(state.url, { action: 'replace' })
      }
    }
  }
}
