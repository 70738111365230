import Controller from './controller'
import { parseJson } from '../utilities'

export default class Visible extends Controller {
  static targets = ['item']

  static options = {
    // Delay in ms between animating items in. Can be overridden per element by setting delay in
    // its data attribute, e.g. `data-visible-stagger="50"`
    staggerInterval: 36,

    // Options passed to `IntersectionObserver`. Can be overridden per element by setting options
    // in its data attribute, e.g. `data-visible='{ "threshold": 1, "rootMargin": "-50px" }'`
    // Available options: https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/IntersectionObserver#Parameters
    intersectionObserverOptions: {
      threshold: 0,
    },
  }

  initialize() {
    this.isPaused = true
    this.queue = []
    this.observers = {}
    this.timeouts = new WeakMap()
  }

  connect() {
    this.element.addEventListener('visible:reset', this.reset)
  }

  disconnect() {
    this.pause()

    for (const [_, observer] of Object.entries(this.observers)) {
      observer.disconnect()
    }

    this.element.removeEventListener('visible:reset', this.reset)
  }

  play() {
    if (!this.isPaused) return
    this.isPaused = false

    this.playInterval = setInterval(() => {
      if (this.queue.length > 0) {
        this.show(this.queue.shift())
      } else {
        this.pause()
      }
    }, this.options.staggerInterval)
  }

  pause() {
    if (this.isPaused) return
    this.isPaused = true

    clearInterval(this.playInterval)
  }

  itemTargetConnected(element) {
    this.observeItem(element)
    this.play()
  }

  itemTargetDisconnected(element) {
    this.removeFromQueue(element)
  }

  observeItem(item) {
    const settings = item.dataset.visible
    let options = this.options.intersectionObserverOptions

    if (settings) {
      options = { ...options, ...parseJson(settings) }
    }

    // Re-use existing IntersectionObserver when settings are the same
    if (!this.observers[settings]) {
      this.observers[settings] = new IntersectionObserver(this.checkIntersection, options)
    }

    this.observers[settings].observe(item)
  }

  checkIntersection = (entries) => {
    entries.forEach(this.checkEntryIntersection)
  }

  checkEntryIntersection = (entry) => {
    const element = entry.target

    if (entry.isIntersecting) {
      this.staggeredShow(element)
    } else {
      if (element.dataset.isVisible && element.dataset.visibleHideable != null) {
        this.hide(element)
      } else {
        this.removeFromQueue(element)
      }
    }
  }

  staggeredShow(element) {
    if (element.dataset.visibleStagger) {
      const delay = Number(element.dataset.visibleStagger)
      const timeout = setTimeout(() => {
        this.show(element)
        this.timeouts.delete(element)
      }, delay)

      this.timeouts.set(element, timeout)
    } else {
      this.queue.push(element)

      if (this.isPaused) {
        this.play()
      }
    }
  }

  show(element) {
    if (!element.dataset.isVisible) {
      element.dataset.isVisible = true
      element.classList.add('is-visible')
    }

    if (element.dataset.visibleHideable == null) {
      this.observers[element.dataset.visible].unobserve(element)
    }

    if (element.dataset.action) {
      this.emit('visible:show', {}, element)
    }
  }

  hide(element) {
    delete element.dataset.isVisible

    element.classList.remove('is-visible')

    if (element.dataset.action) {
      this.emit('visible:hide', {}, element)
    }
  }

  reset = (ev) => {
    const element = ev.target

    if (element.dataset.isVisible) {
      this.hide(element)
      this.observeItem(element)
    } else {
      this.removeFromQueue(element)
    }
  }

  removeFromQueue(element) {
    const index = this.queue.indexOf(element)
    if (index > -1) {
      this.queue.splice(index, 1)

    } else if (this.timeouts.has(element)) {
      clearTimeout(this.timeouts.get(element))
      this.timeouts.delete(element)
    }
  }
}
