import A11yDialog from 'a11y-dialog'
import Controller from './controller'
import { disableBodyScroll, enableBodyScroll, turboRoot } from '../utilities'

export default class Modal extends Controller {
  static values = {
    openOnCreate: { type: Boolean, default: true },
    destroyOnClose: Boolean,
    url: String,
  }

  initialize() {
    this.destroy = this.destroy.bind(this)
    this.onDialogHide = this.onDialogHide.bind(this)

    this.openClass = 'is-open'
    this.dialog = new A11yDialog(this.element)

    // Store this modal so it can be reopened if a modal-link with the same ID is clicked
    this.id = this.data.get('id')
    Modal.instances[this.id] = this

    if (this.openOnCreateValue) {
      this.open()
    }
  }

  disconnect() {
    this.removeInstance()

    if (this.isOpen) {
      this.removeOpenInstance()
      enableBodyScroll()
    }
  }

  destroy() {
    this.element.remove()
  }

  open() {
    if (this.isOpen) return
    this.isOpen = true

    // Add open class after a delay, otherwise transitions may not work (if modal has just been added to the DOM)
    setTimeout(() => {
      this.element.classList.add(this.openClass)
    }, 0)

    if (!Modal.openInstances.length) {
      disableBodyScroll()
    }

    // If modal has a URL, and doesn't match the current history entry - set new history entry data (used by history_controller)
    if (this.urlValue && history.state?.modal?.id !== this.id) {
      // Replace the current page state before pushing modal state - this is so that Turbolinks doesn't attempt to restore
      // the page when going back (as the state object will be missing a `turbo` property)
      const { turbo, cachedTurbo, ...state } = history.state || {}

      history.replaceState({
        ...state,
        cachedTurbo: cachedTurbo || turbo,
        url: location.href,
      }, '', location.href)

      history.pushState(
        {
          ...state,
          cachedTurbo: cachedTurbo || turbo,
          url: this.urlValue,
          modal: {
            id: this.id,
          },
        },
        '',
        this.urlValue,
      )
    }

    Modal.openInstances.push(this)

    // Make sure this modal is on top of all other modals
    if (Modal.zIndex) {
      Modal.zIndex += 1
      this.element.style.zIndex = Modal.zIndex
    } else {
      Modal.zIndex = Number(getComputedStyle(this.element).zIndex)
    }

    // Delay needed after modal is added to the DOM, or A11yDialog may not set focus element & hitting esc won't close modal
    setTimeout(() => {
      this.dialog.show()
      this.dialog.on('hide', this.onDialogHide)
    }, 50)
  }

  close() {
    if (!this.isOpen) return
    this.dialog.hide()
  }

  onDialogHide() {
    if (!this.isOpen) return
    this.isOpen = false

    if (this.destroyOnCloseValue) {
      Modal.instances[this.id] = null

      // Wait until transition ends before removing modal
      setTimeout(this.destroy, 350)
    }

    // Add class to trigger hiding transition
    this.element.classList.remove(this.openClass)
    this.dialog.off('hide', this.onDialogHide)

    this.removeOpenInstance()

    if (!Modal.openInstances.length) {
      enableBodyScroll()
    }

    this.dialog.previouslyFocused?.focus()

    // If modal has a URL, and matches the current history entry - navigate back to unset its URL
    if (this.urlValue && history.state?.modal?.id === this.id) {
      history.back()
    }
  }

  removeInstance() {
    if (Modal.instances[this.id]) {
      delete Modal.instances[this.id]
    }
  }

  removeOpenInstance() {
    const index = Modal.openInstances.indexOf(this)
    if (index > -1) {
      Modal.openInstances.splice(index, 1)
    }
  }
}

Modal.get = (id) => Modal.instances[id] || null

Modal.open = (id) => {
  let modalTarget

  const modal = Modal.get(id)
  if (modal) {
    modal.open()
    modalTarget = modal.element
  } else {
    modalTarget = Modal.create(id)
  }

  return modalTarget
}

Modal.close = (id) => {
  // If called without id param, close all open modals
  if (!id) {
    Modal.openInstances.forEach((modal) => {
      modal.close()
    })

    return
  }

  const modal = Modal.get(id)
  if (modal) {
    modal.close()
  }
}

Modal.create = (id) => {
  const modal = Modal.get(id)
  if (modal) {
    return modal.element
  }

  const template = document.querySelector(`template[data-modal-id="${id}"]`)
  if (!template) {
    console.warn('Modal template not found', id)
    return
  }

  const modalTarget = document.importNode(template.content, true).firstElementChild

  // Modals open automatically when Stimulus finds them in the DOM
  turboRoot().appendChild(modalTarget)

  return modalTarget
}

// Check if a modal is open by ID
Modal.isOpen = (id) => {
  return Modal.openInstances.some((modal) => modal.id === id)
}

Modal.instances = {}

Modal.openInstances = []
