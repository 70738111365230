import { clamp, mapValueToRange } from '../utilities'
import Controller from './controller'

export default class extends Controller {
  static targets = ['tiles', 'logo', 'overlay']

  initialize() {
    this.resize = this.resize.bind(this)
    this.resizeDebounced = this.resizeDebounced.bind(this)
    this.render = this.render.bind(this)
    this.scroll = this.scroll.bind(this)
  }

  connect() {
    this.resize()
    this.resizeObserver = new ResizeObserver(this.resizeDebounced)
    this.resizeObserver.observe(document.body)

    window.addEventListener('scroll', this.scroll, { passive: true })
  }

  disconnect() {
    clearTimeout(this.resizeDebouncedTimeout)

    this.resizeObserver.disconnect()

    window.removeEventListener('scroll', this.scroll)

    if (this.queuedRender) cancelAnimationFrame(this.queuedRender)
  }

  scroll() {
    this.scrollY = window.scrollY

    this.setPosition()
  }

  setPosition() {
    if (this.startY == null) {
      return
    }

    if (this.queuedRender) cancelAnimationFrame(this.queuedRender)
    this.queuedRender = requestAnimationFrame(this.render)
  }

  render() {
    this.tilesTargets.forEach((tile) => {
      this.setElementPosition(tile, 20 * this.scale, 0)
    })

    this.setElementPosition(this.logoTarget, -20 * this.scale, 0)

    const overlayOpacity = clamp(mapValueToRange(this.scrollY, this.startY, this.endY, 1, 0), 0, 1)
    this.overlayTarget.style.opacity = overlayOpacity.toString()
  }

  setElementPosition(element, minY, maxY) {
    const y = mapValueToRange(this.scrollY, this.startY, this.endY, minY, maxY)

    element.style.transform = `translate3d(0, ${y}px, 0)`
  }

  resize() {
    this.scrollY = window.scrollY
    this.endY = document.documentElement.scrollHeight - window.innerHeight
    this.startY = this.endY - this.element.getBoundingClientRect().height
    this.scale = this.tilesTarget.getBoundingClientRect().width / 510

    if (this.element.getBoundingClientRect().height <= window.innerHeight) {
      this.setSticky()
    } else {
      this.unsetSticky()
    }

    this.setPosition()
  }

  resizeDebounced() {
    clearTimeout(this.resizeDebouncedTimeout)
    this.resizeDebouncedTimeout = setTimeout(this.resize, 150)
  }

  setSticky() {
    if (this.isSticky) return
    this.isSticky = true

    this.element.classList.add('is-sticky')
  }

  unsetSticky() {
    if (!this.isSticky) return
    this.isSticky = false

    this.element.classList.remove('is-sticky')
  }
}
