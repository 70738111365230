import Toastify from 'toastify-js'

export const toast = (options) => {
  Toastify({
    gravity: 'bottom',
    position: 'right',
    selector: document.getElementById('toast-container'),
    ...options,
  }).showToast();
}
