import Controller from './controller'

export default class extends Controller {
  async refreshQuantity() {
    let fetchUrl = `/ajax/cart/count`

    if (this.hasRequestInProgress) {
      this.abortController.abort()
    }

    this.abortController = new AbortController()

    this.hasRequestInProgress = true
    let response
    try {
      response = await fetch(fetchUrl, {
        signal: this.abortController.signal,
      })
    } catch (error) {
      this.hasRequestInProgress = false

      if (error.name !== 'AbortError') {
        console.error(error)
      }

      return
    }
    this.hasRequestInProgress = false

    if (response.ok) {
      this.element.innerHTML = await response.text()
    } else {
      // TODO: Show error?
      console.warn(`Failed to refresh cart count`)
    }
  }
}
