import * as Turbo from "@hotwired/turbo"
import Turn from '@domchristie/turn'
import '../styles/app.css'
import './controllers'
import { randomArrayItem } from "./utilities"
import { enableSound, isSoundCookieSet, isSoundEnabled } from "./sound"

// Show a progress bar after 500ms of page load time
// Styled using `.turbo-progress-bar` in CSS
Turbo.setProgressBarDelay(500)

// Use turn for animating page transitions: https://github.com/domchristie/turn
// See view-transitions.css for animation styling
Turn.start()

document.addEventListener('turn:before-exit', () => {
  document.documentElement.style.setProperty('--view-transition-mask-color', randomArrayItem([
    'var(--mid-green)',
    'var(--mid-green-70)',
    'var(--dark-terracotta)',
    'var(--dark-terracotta-70)',
    'var(--light-terracotta)',
    'var(--light-terracotta-70)',
  ]))
})

// Replace #turbo-root element contents when navigating (if element exists), rather than body contents
// Based on https://github.com/hotwired/turbo/blob/ea54ae5ad4b6b28cb62ccd62951352641ed08293/src/core/drive/page_renderer.js#L5-L11
Turbo.PageRenderer.renderElement = (_currentElement, newElement) => {
  if (document.body && newElement instanceof HTMLBodyElement) {
    const currentTurboRoot = document.body.querySelector('#turbo-root')
    const newTurboRoot = newElement.querySelector('#turbo-root')

    if (currentTurboRoot && newTurboRoot) {
      currentTurboRoot.replaceWith(newTurboRoot)
      document.body.className = newElement.className
    } else {
      document.body.replaceWith(newElement)
    }
  } else {
    document.documentElement.appendChild(newElement)
  }
}

// Enable sound if previously enabled (based on existence of sound cookie)
if (!isSoundEnabled() && isSoundCookieSet()) {
  enableSound()
}

// Set hasInteractionOccurred on first page click. This is used in landing_controller to check whether
// sound enabling/disabling splash screen should be shown. Otherwise, sound may be enabled at page
// load based on the existence of the sound cookie, but the landing intro sound would not play (until interaction occurs)
document.addEventListener('click', () => {
  window.hasInteractionOccurred = true
}, { once: true })
