import { isSoundEnabled, disableSound, enableSound, playSound, preloadSound } from '../sound'
import { clamp, disableBodyScroll, enableBodyScroll, mapValueToRange, setCookie } from '../utilities'
import Controller from './controller'

export default class extends Controller {
  static targets = ['letter', 'frame', 'soundDialogProgress']

  connect() {
    if (document.visibilityState !== 'hidden') {
      this.run()
    } else {
      this.pageVisibilityListenerAttached = true
      document.addEventListener('visibilitychange', this.runOnPageVisible)
    }
  }

  disconnect() {
    this.disable()
  }

  runOnPageVisible = () => {
    if (document.visibilityState !== 'hidden') {
      this.removePageVisibilityListener()
      this.run()
    }
  }

  removePageVisibilityListener() {
    this.pageVisibilityListenerAttached = false
    document.removeEventListener('visibilitychange', this.runOnPageVisible)
  }

  start() {
    window.scrollTo(0, 0)
    disableBodyScroll()
    preloadSound('/static/sounds/intro-v2.mp3')

    if (isSoundEnabled() && window.hasInteractionOccurred) {
      this.showLogo()
    } else {
      this.showSoundDialog()
    }
  }

  showSoundDialog() {
    document.documentElement.classList.add('landing-sound-dialog-shown')
    const startTime = new Date()

    this.soundDialogLoop = () => {
      this.soundDialogLoopFrame = requestAnimationFrame(this.soundDialogLoop)
      const progress = clamp(mapValueToRange(Date.now() - startTime, 0, 5000, 0, 1), 0, 1)
      this.soundDialogProgressTarget.style.setProperty('--progress', progress)

      if (progress === 1) {
        this.disableSound()
      }
    }
    this.soundDialogLoopFrame = requestAnimationFrame(this.soundDialogLoop)
  }

  hideSoundDialog() {
    if (this.soundDialogLoopFrame) cancelAnimationFrame(this.soundDialogLoopFrame)
    document.documentElement.classList.remove('landing-sound-dialog-shown')

    this.timeout = setTimeout(() => {
      this.showLogo()
    }, 350)
  }

  showLogo() {
    document.documentElement.classList.add('is-landing')

    playSound('/static/sounds/intro-v2.mp3')

    this.timeout = setTimeout(() => {
      document.documentElement.classList.add('is-landed')

      this.timeout = setTimeout(() => {
        enableBodyScroll()
        document.documentElement.dataset.landed = true
        this.emit('landed', {}, document)
      }, 2250)
    }, 350 * 5);
  }

  run() {
    if (sessionStorage.getItem("landing") || document.cookie.includes('landing=')) {
      document.documentElement.dataset.landed = true
      this.emit('landed', {}, document)
    } else {
      // Show landing again after cookie expires
      setCookie('landing', '1', 14 * 24 * 60 * 60) // Two weeks
      sessionStorage.setItem("landing", true)
      this.start()
    }
  }

  enableSound() {
    enableSound()
    this.hideSoundDialog()
  }

  disableSound() {
    disableSound()
    this.hideSoundDialog()
  }

  beforeCache() {
    this.disable()
  }

  disable() {
    enableBodyScroll()
    clearTimeout(this.timeout)
    document.documentElement.classList.remove('landing-enabled', 'is-landing', 'landing-sound-dialog-shown')
    document.documentElement.classList.add('is-landed', 'no-landing')
    document.documentElement.dataset.landed = true
    this.soundDialogProgressTarget.style.removeProperty('--progress')
    if (this.soundDialogLoopFrame) cancelAnimationFrame(this.soundDialogLoopFrame)
    if (this.pageVisibilityListenerAttached) this.removePageVisibilityListener()
  }
}
