import Controller from './controller'
import { disableBodyScroll, enableBodyScroll } from '../utilities'

export default class extends Controller {
  static targets = ['toggle', 'content']

  initialize() {
    this.onClick = this.onClick.bind(this)
    this.onKeydown = this.onKeydown.bind(this)
    this.close = this.close.bind(this)
  }

  connect() {
    this.isOpen = this.element.classList.contains('is-open')
  }

  disconnect() {
    this.removeEvents()
  }

  toggle() {
    if (this.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  open() {
    if (this.isOpen) return
    this.isOpen = true

    this.element.classList.add('is-open')
    this.contentTarget.classList.add('is-open')

    disableBodyScroll()

    this.toggleTarget.setAttribute('aria-expanded', true)

    setTimeout(() => {
      this.addEvents()
      this.contentTarget.focus()
    }, 50)
  }

  close() {
    if (!this.isOpen) return
    this.isOpen = false

    this.element.classList.add('is-closing')
    this.element.classList.remove('is-open')
    this.contentTarget.classList.add('is-closing')
    this.contentTarget.classList.remove('is-open')

    enableBodyScroll()

    this.toggleTarget.setAttribute('aria-expanded', false)
    this.toggleTarget.focus()

    this.removeEvents()

    this.isClose = null
  }

  onClick(ev) {
    if (!this.contentTarget.contains(ev.target)) {
      this.close()
    }
  }

  onKeydown(ev) {
    if (ev.key === 'Escape') {
      this.close()
    }
  }

  addEvents() {
    window.addEventListener('click', this.onClick)
    window.addEventListener('keydown', this.onKeydown)
  }

  removeEvents() {
    window.removeEventListener('click', this.onClick)
    window.removeEventListener('keydown', this.onKeydown)
  }

  beforeCache() {
    // Close mobile menu when navigating between pages
    this.close()
  }
}
