import { Howl } from 'howler'
import { application } from './controllers'
import { deleteCookie, getCookie, setCookie } from './utilities'

let soundEnabled = false
let loop

const sounds = new Map()
const COOKIE_NAME = 'sound_enabled'

export const playSound = (fileUrl) => {
  if (!isSoundEnabled()) {
    return
  }

  const sound = getSound(fileUrl)
  sound.play()
}

export const preloadSound = (fileUrl) => {
  if (!isSoundEnabled()) {
    return
  }

  getSound(fileUrl) // Preloading is the default howler.js behaviour
}

const getSound = (fileUrl) => {
  let sound

  if (sounds.has(fileUrl)) {
    sound = sounds.get(fileUrl)
  } else {
    sound = new Howl({
      src: fileUrl,
    })
    sounds.set(fileUrl, sound)
  }

  return sound
}

export const enableSound = () => {
  soundEnabled = true
  document.documentElement.classList.add('sound-enabled')

  if (loop) {
    loop.play()
  }

  document.dispatchEvent(new CustomEvent('sound:enable'))
  setCookie(COOKIE_NAME, '1', 365 * 24 * 60 * 60) // One year
}

export const disableSound = () => {
  soundEnabled = false
  document.documentElement.classList.remove('sound-enabled')

  sounds.forEach((sound) => {
    sound.stop()
  })

  if (loop) {
    loop.pause()
  }

  document.dispatchEvent(new CustomEvent('sound:disable'))
  deleteCookie(COOKIE_NAME)
}

export const isSoundEnabled = () => {
  return soundEnabled
}

export const toggleSound = () => {
  if (isSoundEnabled()) {
    disableSound()
  } else {
    enableSound()
  }
}

export const startLoop = (fileUrl) => {
  if (!isSoundEnabled()) {
    return
  }

  if (sounds.has(fileUrl)) {
    loop = sounds.get(fileUrl)
  } else {
    loop = new Howl({
      src: fileUrl,
      loop: true,
    })
    sounds.set(fileUrl, loop)
  }

  loop.play()
}

export const stopLoop = () => {
  if (loop) {
    loop.stop()
    loop = null
  }
}

export const showTooltip = (text) => {
  const tooltip = application.getControllerForElementAndIdentifier(
    document.getElementById('sound-tooltip'),
    'tooltip',
  )

  if (tooltip) {
    if (text) {
      tooltip.setText(text)
    }

    tooltip.show()
  }
}

export const isSoundCookieSet = () => {
  return getCookie(COOKIE_NAME) === '1'
}
