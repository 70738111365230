import { turboRoot } from '../utilities'
import Controller from './controller'
import { computePosition, offset, flip, shift, arrow } from '@floating-ui/dom'

export default class extends Controller {
  static values = {
    placement: String,
  }

  static targets = ['content', 'trigger', 'text', 'close']

  connect() {
    this.text = this.hasTextTarget ? this.textTarget : null
  }

  disconnect() {
    this.destroy()
  }

  destroy = () => {
    this.hide()

    if (this.content) {
      this.content.remove()
      this.content = null
      this.arrow = null
      window.removeEventListener('turbo:before-cache', this.destroy)
    }
  }

  show() {
    if (!this.content) {
      this.content = this.contentTarget.cloneNode(true)
      this.text = this.content.querySelector('[data-tooltip-target="text"]')
      this.arrow = document.createElement('div')
      this.arrow.className = 'tooltip__arrow'
      this.content.appendChild(this.arrow)
      turboRoot().appendChild(this.content)
      window.addEventListener('turbo:before-cache', this.destroy)
      this.close = this.content.querySelector('[data-tooltip-target="close"]')
      
      if (this.close) {
        this.close.addEventListener('click', this.hideDebounced)
      }
    }

    clearTimeout(this.hideDebouncedTimeout)

    if (this.isShown) {
      return
    }
    this.isShown = true

    setTimeout(() => {
      this.content.classList.add('is-shown')
      this.setPosition()
    }, 0)
  }

  hide = (text) => {
    if (!this.isShown) {
      return
    }
    this.isShown = false

    this.content.classList.remove('is-shown')

    if (text) {
      this.setTextAfterTransition(text)
    }
  }
  
  toggle() {
    if (this.isShown) {
      this.hideDebounced()
    } else {
      this.show()
    }
  }

  hideDebounced = () => {
    console.log(99);
    
    clearTimeout(this.hideDebouncedTimeout)
    this.hideDebouncedTimeout = setTimeout(this.hide, 250)
  }

  setPosition() {
    // This is mostly taken from the tooltip tutorial at https://floating-ui.com/docs/tutorial
    computePosition(this.triggerTarget, this.content, {
      placement: this.placementValue || 'top',
      middleware: [
        offset({
          mainAxis: 14,
          alignmentAxis: -16,
        }),
        flip(),
        shift({ padding: 5 }),
        arrow({ element: this.arrow }),
      ],
    }).then(({x, y, placement, middlewareData}) => {
      Object.assign(this.content.style, {
        left: `${x}px`,
        top: `${y}px`,
      })

      const { x: arrowX, y: arrowY } = middlewareData.arrow

      const staticSide = {
        top: 'bottom',
        right: 'left',
        bottom: 'top',
        left: 'right',
      }[placement.split('-')[0]]

      Object.assign(this.arrow.style, {
        left: arrowX != null ? `${arrowX}px` : '',
        top: arrowY != null ? `${arrowY}px` : '',
        right: '',
        bottom: '',
        [staticSide]: '-4px',
      })
    })
  }

  setText(text) {
    this.text.textContent = text
  }

  setTextAfterTransition(text) {
    clearTimeout(this.setTextTimeout)
    this.setTextTimeout = setTimeout(() => {
      this.setText(text)
    }, 200)
  }
}
