let uniqueIdIndex = 0

export const uniqueId = (prefix = '_') => {
  uniqueIdIndex += 1
  return `${prefix}${uniqueIdIndex}`
}

export const elementId = () => uniqueId('_element_')

export const isReducedMotion = () => window.matchMedia('(prefers-reduced-motion: reduce)').matches

export const scrollToPosition = ({ target, top, left, behavior }) => {
  target ||= window
  top ||= 0
  left ||= 0
  behavior ||= 'smooth'

  if (behavior === 'smooth' && isReducedMotion()) {
    behavior = 'auto'
  }

  target.scrollTo({
    top,
    left,
    behavior,
  })
}

export const disableBodyScroll = () => {
  document.body.classList.add('scroll-disabled')
  document.body.style.overflow = 'hidden'
  document.body.style.paddingRight = 'var(--scrollbar-width)'
}

export const enableBodyScroll = () => {
  document.body.classList.remove('scroll-disabled')
  document.body.style.overflow = ''
  document.body.style.paddingRight = ''
}

export const parseJson = (json) => {
  if (!json) return {}

  let parsed

  try {
    parsed = JSON.parse(json)
  } catch (error) {
    parsed = {}
  }

  return parsed
}

export const clamp = (target, min, max) => Math.min(Math.max(target, min), max)

export const mapValueToRange = (value, fromMin, fromMax, toMin, toMax) =>
  toMin + ((value - fromMin) / (fromMax - fromMin)) * (toMax - toMin)

export const isTouch = () => window.matchMedia('(hover: none)').matches

/**
 * Get the active element, accounting for Shadow DOM subtrees.
 * @author Cory LaViska
 * @see: https://www.abeautifulsite.net/posts/finding-the-active-element-in-a-shadow-root/
 */
export const getActiveElement = (root = document) => {
  const activeEl = root.activeElement

  if (!activeEl) return null

  // If there’s a shadow root, recursively find the active element within it.
  // If the recursive call returns null, return the active element
  // of the top-level Document.
  if (activeEl.shadowRoot) return getActiveElement(activeEl.shadowRoot) || document.activeElement

  // If not, we can just return the active element
  return activeEl
}

export const randomArrayItem = (array) => array[Math.floor(Math.random() * array.length)]

export const turboRoot = () => document.body.querySelector('#turbo-root') || document.body

// Map to store ongoing requests
const ongoingRequests = new Map()
export  const responseCache = new Map()

export const fetchWithCache = async (url) => {
  const TIMEOUT_MS = 10000 // 10 seconds

  // Check cache first
  if (responseCache.has(url)) {
    return responseCache.get(url).clone()
  }

  // Check for ongoing requests
  if (ongoingRequests.has(url)) {
    const response = await ongoingRequests.get(url)
    return response.clone()
  }

  // If not in cache and no ongoing request, fetch from network
  const fetchPromise = (async () => {
    const abortController = new AbortController()
    const timeoutId = setTimeout(() => abortController.abort(), TIMEOUT_MS)

    try {
      const networkResponse = await fetch(url, {
        signal: abortController.signal,
      })

      clearTimeout(timeoutId)

      responseCache.set(url, networkResponse)
      return networkResponse.clone()
    } catch (error) {
      if (error.name === 'AbortError') {
        throw new Error(`Request for ${url} timed out after ${TIMEOUT_MS}ms`)
      }
      throw error
    } finally {
      ongoingRequests.delete(url)
    }
  })()

  ongoingRequests.set(url, fetchPromise)

  return fetchPromise
}

export const getInputForLabel = (label) => {
  // Check if the label has a 'for' attribute
  if (label.htmlFor) {
    // If it does, return the input element with the matching id
    return document.getElementById(label.htmlFor)
  }
  // If there's no 'for' attribute, check if the input is nested within the label
  else {
    // Find the first input element within the label
    return label.querySelector('input, select, textarea')
  }
}

// Set a cookie by name
export const setCookie = (name, value, maxAge) => {
  document.cookie = `${name}=${value};max-age=${maxAge};path=/`;
}

// Delete a cookie by name
export const deleteCookie = (name) => {
  document.cookie = `${name}=;max-age=0;path=/`
}

// Get a cookie by name
export const getCookie = (name) => {
  const cookieName = name + '='
  const cookies = decodeURIComponent(document.cookie).split(';')

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i]
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1)
    }
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }

  return '';
}
