import { Application } from '@hotwired/stimulus'
import StimulusControllerResolver, { createViteGlobResolver } from 'stimulus-controller-resolver'
import CartCountController from '../controllers/cart-count_controller'
import DropdownController from '../controllers/dropdown_controller'
import FooterController from '../controllers/footer_controller'
import HeaderController from '../controllers/header_controller'
import HistoryController from '../controllers/history_controller'
import ImageLinksController from '../controllers/image-links_controller'
import LandingController from '../controllers/landing_controller'
import MenuController from '../controllers/menu_controller'
import NavigationController from '../controllers/navigation_controller'
import ProgressController from '../controllers/progress_controller'
import SearchController from '../controllers/search_controller'
import SoundController from '../controllers/sound_controller'
import TooltipController from '../controllers/tooltip_controller'
import VisibleController from '../controllers/visible_controller'

export const application = Application.start()

application.register('cart-count', CartCountController)
application.register('dropdown', DropdownController)
application.register('footer', FooterController)
application.register('header', HeaderController)
application.register('history', HistoryController)
application.register('image-links', ImageLinksController)
application.register('landing', LandingController)
application.register('menu', MenuController)
application.register('navigation', NavigationController)
application.register('progress', ProgressController)
application.register('search', SearchController)
application.register('sound', SoundController)
application.register('tooltip', TooltipController)
application.register('visible', VisibleController)

StimulusControllerResolver.install(
  application,
  createViteGlobResolver(import.meta.glob('./**/*_controller.js')),
)
