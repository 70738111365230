import { toast } from '../toast'
import Controller from './controller'

export default class extends Controller {
  static targets = ['input', 'loader', 'results', 'featured']

  initialize() {
    this.value = this.inputTarget.value.trim()
  }

  disconnect() {
    this.abortRequest()
  }

  fetchResults = async () => {
    const value = this.inputTarget.value.trim()

    // Don't search if current input value as the same as last input value
    if (value === this.value) {
      return
    }

    // Don't search if input value exists, but is less than min accepted length (3 chars)
    if (value.length > 0 && value.length < 3) {
      return
    }

    this.value = value

    this.abortRequest()
    this.abortController = new AbortController()
    this.hasRequestInProgress = true
    let response

    this.showLoader()

    if (location.pathname === '/search') {
      const { turbo, cachedTurbo, ...state } = history.state || {}
      const url = this.value ? `/search?q=${this.value}` : '/search'
      history.replaceState({
        ...state,
        cachedTurbo: cachedTurbo || turbo,
        url,
      }, '', url)
    }

    try {
      response = await fetch(`/ajax/search/results?q=${this.value}`, {
        signal: this.abortController.signal,
      })

      if (response.ok) {
        let results = await response.text()
        results = results.trim()
        this.resultsTarget.innerHTML = results

        // Hide featured content when results exist
        this.featuredTarget.hidden = !!results
        this.resultsTarget.hidden = !results
      } else {
        throw new Error('Failed to fetch search results')
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error(error)

        toast({
          text: 'Search failed',
          className: 'error',
        })
      }
    }

    this.hasRequestInProgress = false
    this.hideLoader()
  }

  fetchResultsDebounced = () => {
    clearTimeout(this.fetchResultsDebouncedTimeout)
    this.fetchResultsDebouncedTimeout = setTimeout(this.fetchResults, 500)
  }

  showLoader() {
    if (this.isLoading) return
    this.isLoading = true
    this.loaderTarget.classList.add('is-shown')
  }

  hideLoader() {
    if (!this.isLoading) return
    this.isLoading = false
    this.loaderTarget.classList.remove('is-shown')
  }

  abortRequest() {
    // Abort last request if still ongoing
    if (this.hasRequestInProgress) {
      this.abortController.abort()
      this.hideLoader()
    }
  }

  beforeCache() {
    this.hideLoader()
  }

  onSubmit(ev) {
    ev.preventDefault()
    this.fetchResults()
  }
}
