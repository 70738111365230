import Controller from './controller'
import { elementId, disableBodyScroll, enableBodyScroll } from '../utilities'

export default class extends Controller {
  static targets = ['content', 'toggle']

  static values = {
    mouse: Boolean,
    scrollLock: { type: Boolean, default: false },
    animateHeight: Boolean,
    events: { type: Boolean, default: true },
  }

  get focusables() {
    if (!this.cachedFocusables) {
      this.cachedFocusables = Array.from(this.contentTarget.querySelectorAll('a')).filter(
        (link) => link.offsetWidth > 0,
      )
    }

    return this.cachedFocusables
  }

  connect() {
    this.onClick = this.onClick.bind(this)
    this.onToggleClick = this.onToggleClick.bind(this)
    this.onKeydown = this.onKeydown.bind(this)
    this.onMouseenter = this.onMouseenter.bind(this)
    this.onMouseleave = this.onMouseleave.bind(this)
    this.resize = this.resize.bind(this)

    if (this.animateHeightValue) {
      this.onHeightTransitionEnd = this.onHeightTransitionEnd.bind(this)
    }

    this.hoverOpenDelay = 125 // Time after mouseenter before showing dropdown
    this.hoverCloseDelay = 250 // Time after mouseleave before hiding dropdown
    this.hoverQuickSwitchDelay = 50 // When switching between dropdowns - time after mouseenter before showing dropdown

    this.elementOpenClass = 'is-open'

    if (!this.contentTarget.id) this.contentTarget.id = elementId()
    this.contentTarget.setAttribute('aria-labelledby', this.toggleTarget.id)

    for (let index = 0; index < this.toggleTargets.length; index++) {
      const element = this.toggleTargets[index]
      if (!element.id) element.id = elementId()
      element.setAttribute('aria-haspopup', 'true')
      element.setAttribute('aria-controls', this.contentTarget.id)
      element.addEventListener('click', this.onToggleClick)
    }

    this.resize()
    window.addEventListener('resize', this.resize)

    if (this.element.classList.contains(this.elementOpenClass)) {
      this.open({ immediate: true })
    }
  }

  disconnect() {
    for (let index = 0; index < this.toggleTargets.length; index++) {
      const element = this.toggleTargets[index]
      element.removeEventListener('click', this.onToggleClick)
    }

    window.removeEventListener('resize', this.resize)
    this.disableMouse()
    this.removeHeightListener()

    if (this.isOpen && this.eventsValue) {
      window.removeEventListener('click', this.onClick)
      window.removeEventListener('keydown', this.onKeydown)
    }
  }

  onToggleClick(ev) {
    ev.preventDefault()
    this.toggle()
  }

  resize() {
    // If this.element has ::before content set to 'touch', don't enable mouse interaction
    const isTouch =
      window
        .getComputedStyle(this.element, '::before')
        .getPropertyValue('content')
        .replace(/['"]+/g, '') === 'touch'

    // Open dropdown on hover if this.element has data-dropdown-mouse-value="true" set
    if (this.mouseValue && !isTouch) {
      this.enableMouse()
    } else {
      this.disableMouse()
    }
  }

  enableMouse() {
    if (this.isMouseEnabled) return
    this.isMouseEnabled = true

    this.element.addEventListener('mouseenter', this.onMouseenter)
    this.element.addEventListener('mouseleave', this.onMouseleave)
  }

  disableMouse() {
    if (!this.isMouseEnabled) return
    this.isMouseEnabled = false

    this.element.removeEventListener('mouseenter', this.onMouseenter)
    this.element.removeEventListener('mouseleave', this.onMouseleave)
  }

  open({ immediate = false } = {}) {
    clearTimeout(this.closeTimeout)

    if (this.isOpen) return
    this.isOpen = true

    this.emit('dropdown:open', { bubbles: true, detail: { dropdown: this } })

    if (this.scrollLockValue) {
      disableBodyScroll()
    }

    this.element.classList.add(this.elementOpenClass)

    for (let index = 0; index < this.toggleTargets.length; index++) {
      const element = this.toggleTargets[index]
      element.setAttribute('aria-expanded', 'true')
    }

    if (this.eventsValue) {
      window.addEventListener('click', this.onClick)
      window.addEventListener('keydown', this.onKeydown)
    }

    if (this.animateHeightValue) {
      if (immediate) {
        this.contentTarget.style.height = 'auto'
      } else {
        this.addHeightListener()
        const contentInner = this.contentTarget.firstElementChild
        const height = contentInner.getBoundingClientRect().height
        this.contentTarget.style.height = `${height}px`
      }
    }

    setTimeout(() => {
      const autofocus = this.contentTarget.querySelector('[autofocus]')
      if (autofocus) {
        autofocus.focus()
      }
    }, 650) // Wait for dropdown visibility transition to end before focusing

    return true
  }

  close() {
    if (!this.isOpen) return
    this.isOpen = false

    this.isHoverOpened = false

    this.emit('dropdown:close', { bubbles: true, detail: { dropdown: this } })

    if (this.scrollLockValue) {
      enableBodyScroll()
    }

    this.element.classList.remove(this.elementOpenClass)

    for (let index = 0; index < this.toggleTargets.length; index++) {
      const element = this.toggleTargets[index]
      element.removeAttribute('aria-expanded')
    }

    if (this.eventsValue) {
      window.removeEventListener('click', this.onClick)
      window.removeEventListener('keydown', this.onKeydown)
    }

    if (this.animateHeightValue) {
      this.addHeightListener()
      const contentInner = this.contentTarget.firstElementChild
      const height = contentInner.getBoundingClientRect().height
      this.element.classList.add('is-closing')
      this.contentTarget.style.height = `${height}px`

      requestAnimationFrame(() => {
        this.contentTarget.style.height = '0'
      })
    }

    return true
  }

  toggle(ev) {
    if (ev) {
      ev.preventDefault()
    }

    if (ev && this.isHoverOpened) {
      this.isHoverOpened = false
    } else if (this.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  // Reduce hover delay when switching from an already open dropdown to another
  setQuickSwitchMode() {
    this.quickSwitchMode = true
  }

  unsetQuickSwitchMode() {
    this.quickSwitchMode = false
  }

  onFocusout(ev) {
    if (ev.relatedTarget && !this.element.contains(ev.relatedTarget)) {
      // Timeout to support switching quickly between dropdowns on click. Without this, this dropdown's
      // `dropdown:close` event will be fired before next dropdown's `dropdown:open`, causing both to transition
      this.closeTimeout = setTimeout(() => {
        this.close()
      }, 175)
    }
  }

  onClick(ev) {
    let notToggle = false
    for (let index = 0; index < this.toggleTargets.length; index++) {
      if (notToggle == true) {
        return
      }
      notToggle = this.toggleTargets[index].contains(ev.target)
    }

    if (!this.contentTarget.contains(ev.target) && !notToggle) {
      this.close()
    }
  }

  onElementKeydown(ev) {
    const up = ev.key === 'ArrowUp'
    const down = ev.key === 'ArrowDown'

    if (!up && !down) {
      return
    }

    // Prevent default arrow action of scrolling the page.
    ev.preventDefault()

    // Switch focus to next/previous focusable element.
    const index = this.focusables.indexOf(ev.target)
    const { length } = this.focusables
    const nextFocusable = this.focusables[(index + (down ? 1 : -1) + length) % length]

    if (nextFocusable) {
      const focus = () => {
        nextFocusable.focus()
      }

      if (this.isOpen) {
        focus()
      } else {
        this.open()
        setTimeout(focus, 100)
      }
    }
  }

  onKeydown(ev) {
    if (ev.key === 'Escape') {
      this.close()
    }
  }

  onMouseenter() {
    clearTimeout(this.mouseleaveTimeout)

    if (!this.isMouseEnabled) return

    const delay = this.quickSwitchMode ? this.hoverQuickSwitchDelay : this.hoverOpenDelay

    this.mouseenterTimeout = setTimeout(() => {
      if (!this.isOpen) {
        this.isHoverOpened = true
        this.open()
      }
    }, delay)
  }

  onMouseleave() {
    clearTimeout(this.mouseenterTimeout)

    if (!this.isMouseEnabled || !this.isHoverOpened) return

    this.mouseleaveTimeout = setTimeout(() => {
      this.close()
    }, this.hoverCloseDelay)
  }

  addHeightListener() {
    if (this.hasHeightListener) return
    this.hasHeightListener = true

    this.contentTarget.addEventListener('transitionend', this.onHeightTransitionEnd)
  }

  removeHeightListener() {
    if (!this.hasHeightListener) return
    this.hasHeightListener = false

    this.contentTarget.removeEventListener('transitionend', this.onHeightTransitionEnd)
  }

  onHeightTransitionEnd(ev) {
    const target = ev.target

    if (target.dataset.dropdownTarget === 'content' && ev.propertyName === 'height') {
      if (target.getBoundingClientRect().height > 2) {
        // 2 allows for 1px border height x 2
        target.style.height = 'auto'
      } else {
        target.style.height = ''
        this.element.classList.remove('is-closing')
      }

      this.removeHeightListener()
    }
  }
}
